@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 240 20% 98%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 205 59.34% 35.69%;
    --primary-foreground: 0 0% 20%;
    --secondary: 228 19% 95%;
    --secondary-foreground: 212 11% 47%;
    --muted: 228 19% 95%;
    --muted-foreground: 221 12% 74%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 357.52, 63.32%, 44.9%;
    --border: 20 5.9% 90%;
    --input: 212 11% 47%;
    --ring: 205 59.34% 35.69%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 238, 238, 238;
  --background-end-rgb: 238, 238, 238;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 238, 238, 238;
    --background-end-rgb: 238, 238, 238;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-weight: 200;
  font-size: 16px;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  scroll-behavior: smooth;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

#email-body img,
#email-body picture,
#email-body video,
#email-body canvas,
#email-body svg {
  display: unset;
}

input {
  all: unset;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

#root,
#__next {
  isolation: isolate;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0 5px 5px 0;
}

*::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background: orange;
}

pre {
  white-space: pre-wrap;
  font-family: sans-serif;
}

.backgroundBlur {
  width: 50%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  background: orange;
  transform: translate(-50%, 100%) rotateZ(-45deg);
  transform-origin: left;
  z-index: -1;
  filter: blur(100px);
  opacity: 0.1;
}
